<template>
  <q-page padding>
    <q-toolbar class="column">
      <q-toolbar-title class="text-5 q-mr-auto q-ma-sm">{{ $t('campaigns') }}</q-toolbar-title>
      <q-breadcrumbs class="q-mr-auto q-ma-sm">
        <q-breadcrumbs-el label="Painel de Controle" to="/ " />
        <q-breadcrumbs-el label="Campanhas" exact />
      </q-breadcrumbs>
    </q-toolbar>
    <q-separator spaced />
    <q-tabs
      v-show="channelsList.length > 0"
      v-model="tab"
      dense
      class="text-grey"
      active-color="primary"
      indicator-color="primary"
      align="left"
    >
      <q-tab name="allcampaigns" label="Todas as Campanhas" no-caps />
      <q-tab name="mycampaigns" label="Minhas Campanhas" no-caps />
    </q-tabs>
    <q-separator />
    <q-tab-panels v-model="tab" animated v-show="channelsList.length > 0">
      <q-tab-panel name="allcampaigns">
        <header-my-campaign
          v-if="tab == 'allcampaigns'"
          :filters="['campaign', 'category', 'type', 'channel']"
          :channelsList="channelsList"
          :categoriesList="categoriesList"
          :campaignsAllLocal="campaignsAllLocal"
          type="all"
        />
        <list-my-campaign v-show="tab == 'allcampaigns'" type="all" />
      </q-tab-panel>

      <q-tab-panel name="mycampaigns">
        <header-my-campaign
          v-if="tab == 'mycampaigns'"
          :filters="['channel', 'campaign']"
          :channelsList="channelsList"
          type="mycampaigns"
        />
        <list-my-campaign-afiliate v-if="tab == 'mycampaigns'" type="mycampaigns" />
      </q-tab-panel>
    </q-tab-panels>
    <form-my-campaign v-if="dialogCampaign" v-show="channelsList.length > 0" />
    <q-banner
      rounded
      inline-actions
      class="text-blue bg-blue-2 q-mt-md q-pa-md text-center"
      v-show="channelsList.length === 0"
    >
      <q-icon name="mdi-information-outline" size="lg" /> É necessário que você tenha pelo menos 1 canal aprovado na
      plataforma para ter acesso às informações das campanhas
    </q-banner>
  </q-page>
</template>

<script>
import HeaderMyCampaign from '@/components/campaign/HeaderMyCampaign.vue';
import ListMyCampaign from '@/components/campaign/ListMyCampaign.vue';
import ListMyCampaignAfiliate from '@/components/campaign/ListMyCampaignAfiliate.vue';
import FormMyCampaign from '@/components/campaign/FormMyCampaign.vue';
import CampaignMixin from '@/mixins/CampaignMixin';
import AffiliateService from '@/services/AffiliateService';

export default {
  components: {
    ListMyCampaign,
    HeaderMyCampaign,
    FormMyCampaign,
    ListMyCampaignAfiliate
  },
  name: 'MyCampaigns',
  mixins: [CampaignMixin, AffiliateService],
  props: {
    id: {
      type: [String, Number]
    }
  },
  data() {
    return {
      tab: 'allcampaigns',
      status: [],
      channelsList: [],
      categoriesList: [],
      campaignsAllLocal: []
    };
  },
  methods: {
    async getCampaignsAll() {
      try {
        const { data, status } = await this.$http.get(`redirect?app=ADVERTISER&path=/api/get/campaign/filter/`);
        if (status === 200) {
          this.campaignsAllLocal = data;
        }
      } catch (error) {
        if (error.response && error.response.status === 500)
          this.errorNotify('Estamos com problemas no momento. Tente novamente mais tarde.');
        else this.errorNotify(error);
      }
    },

    async getCategories() {
      try {
        const { data, status } = await this.$http.get('redirect?app=ADVERTISER&path=/api/get/campaign/category/filter');
        if (status === 200) this.categoriesList = data;
      } catch (error) {
        if (error.response && error.response.status === 500)
          this.errorNotify('Estamos com problemas no momento. Tente novamente mais tarde.');
        else this.errorNotify(error);
      }
    },

    async getChannels() {
      // this.onLoading(true);
      try {
        const { data, status } = await this.filterAffiliateSiteActive();
        if (status === 200) this.channelsList = data;
      } catch (error) {
        if (error.response && error.response.status === 500)
          this.errorNotify('Estamos com problemas no momento. Tente novamente mais tarde.');
        else this.errorNotify(error);
      } finally {
        // this.onLoading(false);
      }
    }
  },
  mounted() {
    this.getChannels();
    this.getCategories();
    this.getCampaignsAll();
  }
};
</script>
